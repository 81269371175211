import { createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { http } from '../../../helpers/request';
import {
    ConfirmEmailRequest,
    ContinueSessionResponse,
    GetRegisterReferencesResponse,
    LoginRequest,
    LoginResponse,
    ResetPasswordRequest,
    SignUpRequest,
} from './types';

const baseUrl = `/users`;
const ssoUrl = `/sso`;
const referencesUrl = '/references';

export const registerAction = createAsyncThunk<any | undefined, SignUpRequest>(
    'auth/register',
    async (data: SignUpRequest) => {
        const result = await http<any, SignUpRequest>(
            {
                path: `${baseUrl}/signUp `,
                method: 'post',
                body: data,
            },
            false,
        );
        console.log('SignUp:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const confirmEmailAction = createAsyncThunk<any | undefined, ConfirmEmailRequest>(
    'auth/confirmEmail',
    async (data: ConfirmEmailRequest) => {
        const result = await http<any, ConfirmEmailRequest>(
            {
                path: `${baseUrl}/ConfirmSignUp `,
                method: 'post',
                body: data,
            },
            false,
        );
        console.log('ConfirmSignUp:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const loginAction = createAsyncThunk<LoginResponse | undefined, LoginRequest>(
    'auth/login',
    async (data: LoginRequest) => {
        const result = await http<LoginResponse, LoginRequest>(
            {
                path: `${baseUrl}/login`,
                method: 'post',
                body: data,
            },
            false,
            false,
        );
        if (result?.parsedBody?.message === 'Success') {
            const parsedToken = jwt_decode(result.parsedBody.tokens.accessToken) as any;
            localStorage.setItem('Expired', parsedToken?.exp);
            localStorage.setItem('SmSession', result.parsedBody.tokens.accessToken);
            localStorage.setItem('SmRefreshSession', result.parsedBody.tokens.refreshToken);
        }
        console.log('Login:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const loginSsoAction = createAsyncThunk<LoginSsoResponse | undefined, LoginSsoRequest>(
    'auth/loginSso',
    async (data: LoginSsoRequest) => {
        const result = await http<LoginSsoResponse, LoginSsoRequest>(
            {
                path: `${ssoUrl}/login-url`,
                method: 'post',
                body: data,
            },
            false,
            false,
        );
        console.log('Login:');
        console.log(result.parsedBody);
        return result.parsedBody;
    },
);

export const authSsoWithPbLoginAction = createAsyncThunk<LoginResponse | undefined, AuthSsoRequest>(
    'auth/authSsoWithPbLogin',
    async (data: AuthSsoRequest) => {
        console.log('authSsoAction Request:');
        console.log(data);
        const result = await http<LoginResponse, AuthSsoRequest>(
            {
                path: `${ssoUrl}/sso-auth-with-pb-login`,
                method: 'post',
                body: data,
            },
            false,
            false,
        );
        if (result?.parsedBody?.message === 'Success') {
            const parsedToken = jwt_decode(result.parsedBody.tokens.accessToken) as any;
            localStorage.setItem('Expired', parsedToken?.exp);
            localStorage.setItem('SmSession', result.parsedBody.tokens.accessToken);
            localStorage.setItem('SmRefreshSession', result.parsedBody.tokens.refreshToken);
        }
        return result.parsedBody;
    },
);

export const authSsoNoPbLoginAction = createAsyncThunk<AuthSsoResponse | undefined, AuthSsoRequest>(
    'auth/authSsoNoPbLogin',
    async (data: AuthSsoRequest) => {
        console.log('authSsoAction Request:');
        console.log(data);
        const result = await http<AuthSsoResponse, AuthSsoRequest>(
            {
                path: `${ssoUrl}/sso-auth-no-pb-login`,
                method: 'post',
                body: data,
            },
            false,
            false,
        );
        return result.parsedBody;
    },
);

export const logOutAction = createAsyncThunk<void, undefined>('logout/auth', async () => {
    await http<undefined, undefined>({
        path: `${baseUrl}/logout`,
        method: 'post',
    });
});

export const forgotPasswordAction = createAsyncThunk<any | undefined, string>(
    'forgotPassword/auth',
    async (data: string) => {
        const result = await http<undefined, { email: string }>(
            {
                path: `${baseUrl}/forgot-password`,
                method: 'post',
                body: { email: data },
            },
            false,
            false,
        );
        return result.parsedBody;
    },
);

export const resetPasswordAction = createAsyncThunk<any, ResetPasswordRequest>(
    'resetPassword/auth',
    async (data: ResetPasswordRequest) => {
        console.log(data);

        const result = await http<undefined, ResetPasswordRequest>(
            {
                path: `${baseUrl}/restore-password`,
                method: 'post',
                body: data,
            },
            false,
        );
        return result.parsedBody;
    },
);

export const getRegisterReferencesAction = createAsyncThunk<
    GetRegisterReferencesResponse | undefined,
    undefined
>('references/getRegisterReferences', async () => {
    const result = await http<GetRegisterReferencesResponse, undefined>({
        path: `${referencesUrl}/free`,
        method: 'get',
    });

    console.log('References/Free:');
    console.log(result.parsedBody);
    return result.parsedBody;
});

export const continueSessionAction = createAsyncThunk<
    ContinueSessionResponse | undefined,
    undefined
>('auth/continueSession', async () => {
    const result = await http<ContinueSessionResponse, undefined>({
        path: `${baseUrl}/refresh-session`,
        method: 'get',
    });

    console.log('ContinueSession:');
    console.log(result.parsedBody);
    return result.parsedBody;
});
