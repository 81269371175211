import React, { useState, useEffect } from 'react';
import { blueDark01 } from '../../../styles/colors';
import Switcher from '../../common/switcher/Switcher';
import { TeamMember } from '../../../redux/actions/projectActions/types';
import { GetCollaboratorsResponse } from '../../../redux/actions/projectActions/types';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import {
    ProductUploadContainer,
    ProductUploadSwitcherContainer,
    UploadContentContainer,
    UploadFileSection,
    UploadInfoSpin,
    UploadsContent,
    UploadSwitcherTitle,
} from './UploadsInfo.styles';
import UploadTable, { getCSSClassNameByUploadType } from './uploadTable/UploadTable';
import { SelectData } from '../../../models/common/types';
import { FavoriteType, UploadsFieldKey } from '../../../models/product/enums';
import { ProjectPermissionTypes } from '../../../models/permission/enums';
import { MergedFavoriteProduct } from '../../favoritProducts/FavoriteProducts.types';
import { UploadsTableFields } from './Uploads.types';
import UploadFileForm from './uploadFileForm/UploadFileForm';
import { Product } from '../../../models/product/types';
import { usePermissions } from '../../../hooks/permissions';
import { MFRProject } from '../../../models/project/types';
import { ActionResponse } from '../../../models/actionResponse/types';
import { isFunctionalityEnabled } from '../../../../config/functionalityAvailability';
import { UPLOAD_PHOTOS } from '../../../../config/paramsConfig';
import { getUploadedImagesCountAction } from '../../../redux/actions/productActions';
import { getFavoriteUploadedImagesCountAction } from '../../../redux/actions/favoriteProductsActions';
import { GetUploadedDocumentsAndImagesCountResponse } from '../../../redux/actions/productActions/types';
import { Spin } from 'antd';

export enum UploadType {
    DOCUMENT = 'document',
    PICTURE = 'picture',
}

export interface Props {
    allDocumentTypes: Array<SelectData> | undefined;
    favoriteType?: FavoriteType;
    product: MergedFavoriteProduct | Product;
    project?: MFRProject;
    commentId?: string;
    openByDefault?: boolean;
    uploadType: UploadType;
    teamMembers: TeamMember[];
    docAndImagesUploads: Array<GetUploadedDocumentsAndImagesCountResponse>;
}

export interface DocumentsState {
    sorting: number;
    field: UploadsFieldKey;
    files: Array<UploadsTableFields>;
    pending: boolean;
}

export interface UploadedImageData {
    id: number;
    file: UploadsTableFields;
    url: string;
    name: string;
    altText: string;
    description: string;
}

export const filterDocumentTypesByUploadType = (
    documentTypes: SelectData[] | undefined,
    uploadType: UploadType,
): SelectData[] => {
    if (!documentTypes) {
        return [];
    }

    if (uploadType === UploadType.DOCUMENT) {
        return documentTypes.filter(
            (documentType) => documentType.name.toLowerCase() !== UploadType.PICTURE,
        );
    }

    return documentTypes.filter(
        (documentType) => documentType.name.toLowerCase() === UploadType.PICTURE,
    );
};

export const getPictureDocumentTypeId = (
    allDocumentTypes: Array<SelectData> | undefined,
): number | undefined => {
    if (!allDocumentTypes) {
        return undefined;
    }

    const documentTypeF = allDocumentTypes.find(
        (documentType) => documentType.name.toLowerCase() === UploadType.PICTURE.toLowerCase(),
    );

    return documentTypeF ? Number(documentTypeF.id) : undefined;
};

interface State {
    clicked: boolean;
    newFile: UploadsTableFields | null;
}

const ProductUploads: React.FC<Props> = ({
    allDocumentTypes,
    favoriteType,
    product,
    project,
    commentId,
    uploadType,
    openByDefault,
    teamMembers,
    docAndImagesUploads,
}) => {
    const [state, setState] = useState<State>({
        clicked: false,
        newFile: null,
    });
    const [isFormPending, setIsFormPending] = useState(false);

    if (commentId === '-1') {
        commentId = undefined;
    }
    const { checkProductActionPermission } = usePermissions();

    const dispatch = useAppDispatch();
    const selector = useAppSelector((combinedState) => combinedState);

    const [filteredDocumentTypes, setFilteredDocumentTypes] = useState<SelectData[]>([]);
    const [imagesCount, setImagesCount] = useState(0);

    const handleClicked = () => {
        setState({ ...state, clicked: !state.clicked });
    };

    const addNewFileToTable = (file: UploadsTableFields) => {
        setState({ ...state, newFile: file });
        if (file.documentTypeId === getPictureDocumentTypeId(allDocumentTypes)) {
            setImagesCount(imagesCount + 1);
        }
    };

    useEffect(() => {
        getUploadedImagesCount();
    }, []);

    useEffect(() => {
        if (commentId === 'share') {
            setState({ ...state, clicked: true });
        }
        if (allDocumentTypes) {
            setFilteredDocumentTypes(filterDocumentTypesByUploadType(allDocumentTypes, uploadType));
        }
    }, [commentId]);

    useEffect(() => {
        if (openByDefault) {
            setState({ ...state, clicked: true });
        }
    }, [openByDefault]);

    useEffect(() => {
        if (docAndImagesUploads) {
            getMFRImagesCount();
        }
    }, [docAndImagesUploads]);

    const getMFRImagesCount = async () => {
        if (docAndImagesUploads) {
            let uploadedImagesCount = 0;
            for (var i = 1; i < docAndImagesUploads.length; i++) {
                if (
                    docAndImagesUploads[i].uploadType === 'Picture' &&
                    docAndImagesUploads[i].projectProductId === product.projectProductId
                ) {
                    uploadedImagesCount += docAndImagesUploads[i].count;
                }
            }
            setImagesCount(uploadedImagesCount);
        }
    };

    const getFavoriteImagesCount = async () => {
        if (favoriteType === undefined) {
            return;
        }

        const { payload } = (await dispatch(
            getFavoriteUploadedImagesCountAction({
                productFavoriteId: product.id,
                uploadType,
            }),
        )) as ActionResponse<number>;

        if (payload?.data) {
            setImagesCount(payload.data);
        }
    };

    const getUploadedImagesCount = async () => {
        if (favoriteType !== undefined) {
            await getFavoriteImagesCount();
        } else {
            await getMFRImagesCount();
        }
    };

    const isSharedProject = () => {
        var member = null;
        if (teamMembers && teamMembers.length > 0) {
            member = teamMembers.filter(
                (memberF) => memberF.userId === selector.authReducer.user?.id,
            );
        }
        return member && member.length > 0;
    };

    const getImagesTitle = () => {
        return `Images (${imagesCount})`;
    };

    const getUploadSwitcherTitle = () => {
        switch (uploadType) {
            case 'document':
                return 'Documents';
            case 'picture':
                return isFunctionalityEnabled(UPLOAD_PHOTOS) ? getImagesTitle() : 'Documents';
            default:
                return '';
        }
    };

    return (
        <UploadInfoSpin spinning={isFormPending}>
            <ProductUploadContainer className={getCSSClassNameByUploadType(uploadType)}>
                <ProductUploadSwitcherContainer>
                    <Switcher
                        id={''}
                        clicked={state.clicked}
                        handleSwitch={handleClicked}
                        width={20}
                        height={20}
                    />
                    <UploadSwitcherTitle weight={400} height={18} size={14} color={blueDark01}>
                        {getUploadSwitcherTitle()}
                    </UploadSwitcherTitle>
                </ProductUploadSwitcherContainer>
                <UploadsContent className={state.clicked ? 'clicked' : ''}>
                    <UploadContentContainer>
                        {state.clicked && (
                            <UploadTable
                                product={product}
                                favoriteType={favoriteType}
                                newFile={state.newFile}
                                allDocumentTypes={allDocumentTypes}
                                project={project}
                                uploadType={uploadType}
                                setImagesCount={setImagesCount}
                            />
                        )}
                        {(!checkProductActionPermission(favoriteType, project?.userRole) ||
                            project?.userRole === ProjectPermissionTypes.COMMENTER ||
                            isSharedProject()) && (
                            <UploadFileSection>
                                <UploadFileForm
                                    allDocumentTypes={filteredDocumentTypes}
                                    product={product}
                                    favoriteType={favoriteType}
                                    setNewFile={addNewFileToTable}
                                    uploadType={uploadType}
                                    onPending={(pending) => setIsFormPending(pending)}
                                />
                            </UploadFileSection>
                        )}
                    </UploadContentContainer>
                </UploadsContent>
            </ProductUploadContainer>
        </UploadInfoSpin>
    );
};

export default ProductUploads;
