import styled from 'styled-components';

export const TooltipStyles = styled('div')`
    min-width: 320px;
    padding: 8px 8px 10px;
    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif;
    .tooltip {
        text-align: left;
        color: #000;
        h1 {
            font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
            font-size: 12px !important;
            font-weight: normal;
            line-height: 15px;
        }
        h2 {
            font-family: Proxima N W15 Bold, Arial, Helvetica Neue, Helvetica, sans-serif;
            border-top: 1px solid #000;
            color: #000 !important;
            font-size: 12px !important;
            font-weight: normal;
            padding-top: 0.25em !important;
            margin-bottom: 1em !important;
            text-transform: uppercase !important;
            line-height: 15px;
        }
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            font-weight: 400;
            font-size: 11.1px !important;
            margin-bottom: 0.25em;
            line-height: 13.75px;
            + h2 {
                margin-top: 0.75em !important;
            }
            + p {
                margin: 0;
            }
            b {
                font-family: Proxima N W15 Bold;
                font-weight: 700 !important;
            }
            .name {
                font-family: Proxima N W15 Bold;
                font-weight: normal !important;
            }
            &.rollover_wrap {
                display: block;
                .rollover_label_wide {
                    float: left;
                    width: 20%;
                }
                .rollover_text_short {
                    float: right;
                    width: 79%;
                }
                .rollover_label {
                    float: left;
                    width: 13%;
                }
                .rollover_text {
                    float: right;
                    width: 86%;
                }
                &:after {
                    clear: both;
                    content: '.';
                    display: block;
                    font-size: 0;
                    height: 0;
                    line-height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }
        a {
            color: #3f70c0;
            text-decoration: underline !important;
            background-color: unset;
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
        }
        .tooltip-row {
            display: flex;
            margin-bottom: 0.35rem;
        }
        strong {
            font-family: Proxima N W15 Bold;
            font-weight: 400;
        }
        ul {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            font-weight: 400;
            border: 1px solid #ffffb8 !important;
            font-size: 11px !important;
        }
        &.tooltip-ec3listing {
            width: 450px;
        }
    }
    .tooltip-cmfdt {
        text-align: left;
        color: #000;
        padding-left: 3.75px;
        h1 {
            font-family: DIN Next W01 Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
            font-size: 12px !important;
            font-weight: 700 !important;
            line-height: 15px;
        }
        h2 {
            font-family: DIN Next W01 Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
            border-top: 1px solid #000;
            color: #000 !important;
            font-size: 12px !important;
            font-weight: 700 !important;
            padding-top: 0.25em !important;
            margin-bottom: 1em !important;
            text-transform: uppercase !important;
            line-height: 15px;
        }
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            font-weight: 400;
            font-size: 11.1px !important;
            margin-bottom: 0.25em;
            line-height: 13.75px;
            + h2 {
                margin-top: 0.75em !important;
            }
            + p {
                margin: 0;
            }
            &.rollover_wrap {
                display: block;
                .rollover_label_wide {
                    float: left;
                    width: 20%;
                }
                .rollover_text_short {
                    float: right;
                    width: 79%;
                }
                .rollover_label {
                    float: left;
                    width: 13%;
                }
                .rollover_text {
                    float: right;
                    width: 86%;
                }
                &:after {
                    clear: both;
                    content: '.';
                    display: block;
                    font-size: 0;
                    height: 0;
                    line-height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }
            }
        }
        a {
            color: #00237B;
            text-decoration: none !important;
            background-color: unset;
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
        }
        .tooltip-row {
            display: flex;
            margin-bottom: 0.35rem;
        }
        strong {
            font-family: Proxima N W15 Bold;
            font-weight: 400;
        }
        ul {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            font-weight: 400;
            border: 1px solid #ffffb8 !important;
            font-size: 11px !important;
        }
    }
    .co2-padding {
        padding-right: 1px;
    }
    &.tooltip-epd {
        width: 300px;
    }
    &.tooltip-epdpt {
        width: 300px;
    }
    &.tooltip-cmf {
        width: 600px;
    }
    &.tooltip-cmfdt {
        width: 360px;
    }
    &.tooltip-declare {
        width: 300px;
    }
    &.tooltip-ec3 {
        overflow: auto;
        width: 718px !important;
        .tooltip {
            padding: 0 7px;
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                margin-bottom: 15px !important;
                .red-label {
                    strong {
                        font-weight: 400 !important;
                    }
                }
            }
            hr {
                margin: 1em 0;
                background: #000;
                height: 1px;
                border: none;
            }
            .left {
                width: 353px;
                float: left;
                margin-right: 10px;
                padding-top: 3px;
                p {
                    a {
                        font-size: 11px;
                        font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                    }
                }
            }
            .right {
                width: 323px;
                float: left;
                img {
                    margin-bottom: 10px;
                }
                a {
                    font-size: 11px;
                    font-family: Proxima N W15 Bold;
                }
            }
            .labels {
                .l {
                    float: left;
                    margin-right: 5px;
                    clear: both;
                }
                p {
                    float: left;
                    clear: none;
                    width: 332px;
                    margin-bottom: 7px !important;
                }
                .l5 {
                    background: #555c55;
                }
                .l4 {
                    background: #007d00;
                }
                .l3 {
                    background: #369c27;
                }
                .l2 {
                    background: #45c500;
                }
                .l1 {
                    background: #58f00d;
                }
            }
        }
        .logo {
            display: block;
            margin-bottom: 15px;
        }
        .l {
            color: #fff;
            width: 30px;
            height: 13px;
            display: block;
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            text-align: center;
        }
    }

    &.tooltip-epdpt {
        overflow: auto;
        .tooltip {
            padding: 0 1px;
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                .red-label {
                    strong {
                        font-weight: 400 !important;
                    }
                }
            }
            hr {
                margin: 1em 0;
                background: #000;
                height: 1px;
                border: none;
            }
            .left {
                width: 353px;
                float: left;
                margin-right: 10px;
                padding-top: 3px;
                p {
                    a {
                        font-size: 11px;
                        font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                    }
                }
            }
            .right {
                width: 323px;
                float: left;
                img {
                    margin-bottom: 10px;
                }
                a {
                    font-size: 11px;
                    font-family: Proxima N W15 Bold;
                }
            }
            .labels {
                .l {
                    float: left;
                    margin-right: 5px;
                    clear: both;
                }
                p {
                    float: left;
                    clear: none;
                    width: 332px;
                    margin-bottom: 7px !important;
                }
                .l5 {
                    background: #555c55;
                }
                .l4 {
                    background: #007d00;
                }
                .l3 {
                    background: #369c27;
                }
                .l2 {
                    background: #45c500;
                }
                .l1 {
                    background: #58f00d;
                }
            }
        }
        .logo {
            display: block;
            margin-bottom: 15px;
        }
        .l {
            color: #fff;
            width: 30px;
            height: 13px;
            display: block;
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            text-align: center;
        }
    }

    &.tooltip-cmf {
        overflow: auto;
        .tooltip {
            padding: 0 1px;
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                .red-label {
                    strong {
                        font-weight: 400 !important;
                    }
                }
            }
            hr {
                margin: 1em 0;
                background: #000;
                height: 1px;
                border: none;
            }
            .left {
                width: 353px;
                float: left;
                margin-right: 10px;
                padding-top: 3px;
                p {
                    a {
                        font-size: 11px;
                        font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                    }
                }
            }
            .right {
                width: 323px;
                float: left;
                img {
                    margin-bottom: 10px;
                }
                a {
                    font-size: 11px;
                    font-family: Proxima N W15 Bold;
                }
            }
            .labels {
                .l {
                    float: left;
                    margin-right: 5px;
                    clear: both;
                }
                p {
                    float: left;
                    clear: none;
                    width: 332px;
                    margin-bottom: 7px !important;
                }
                .l5 {
                    background: #555c55;
                }
                .l4 {
                    background: #007d00;
                }
                .l3 {
                    background: #369c27;
                }
                .l2 {
                    background: #45c500;
                }
                .l1 {
                    background: #58f00d;
                }
            }
        }
        .logo {
            display: block;
            margin-bottom: 15px;
        }
        .l {
            color: #fff;
            width: 30px;
            height: 13px;
            display: block;
            font-weight: 700;
            font-size: 10px;
            line-height: 13px;
            text-align: center;
        }
    }


    .tooltip-hpd {
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            a {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            }
        }
        ul {
            &.list-bulleted {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                display: block !important;
                list-style-type: disc !important;
                padding-left: 12px;
                margin: 0 0 0.65rem !important;
                overflow: hidden !important;
            }
        }
    }
    .tooltip-c2c {
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            a {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            }
        }
    }
    &.program {
        width: 320px;
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            a {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            }
        }

        &.tooltip-ec3 {
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                a {
                    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                }
            }
        }
        &.tooltip-epdpt {
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                a {
                    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                }
            }
        }
        &.tooltip-cmf {
            width: 600px;
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                a {
                    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                }
            }
        }
    }
    &.program-cmf {
        width: 407px;
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            a {
                font-family: 'Proxima N W15 Bold', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            }
        }
        &.tooltip-cmf {
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                a {
                    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                }
            }
        }
    }
    &.program-cmfdt {
        width: 358px;
        p {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            a {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            }
            b {
                font-family: Proxima N W15 Bold;
                font-size: 12px;
                font-weight: normal;
                line-height: 14px;
                text-align: left;
                color: #00237B;
            }
        }
        &.tooltip-cmfdt {
            p {
                font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                a {
                    font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
                }
            }
        }
    }
    &.mfr {
        width: 496px;
        ul {
            font-family: 'Proxima Nova', Arial, Helvetica Neue, Helvetica, sans-serif !important;
            display: block !important;
            list-style-type: disc !important;
            padding-left: 12px;
            margin: 0 0 0.65rem !important;
            overflow: hidden !important;
        }
    }
`;
