import styled from 'styled-components';
import {
    blueDark01,
    blueDark02,
    blueLink,
    grayLight01,
    grayLight05,
    white,
} from '../../../styles/colors';
import { PrimaryButton, BoldText } from '../../../styles/common.styles';
import { Spin } from 'antd';

export const ProductUploadContainer = styled('div')`
    background-color: ${grayLight05};
    padding: 4px 12px;
    border-top: 1px solid ${grayLight01};
`;

export const ProductUploadSwitcherContainer = styled('div')`
    display: -webkit-flex;
    align-items: center;
    &.clicked {
        padding-bottom: 18px;
    }
`;

export const UploadSwitcherTitle = styled(BoldText)`
    margin-left: -20px;
`;

export const UploadsContent = styled('div')`
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 200ms ease, opacity 0ms ease;
    background-color: ${grayLight05};
    &.clicked {
        max-height: 1000px;
        opacity: 1;
        transition: max-height 200ms ease, opacity 0ms ease;
        background-color: ${grayLight05};
        @media screen and (max-width: 760px) {
            max-height: 1400px;
        }
    }
    @media screen and (max-width: 760px) {
        display: block;
    }
`;

export const InviteMFRSection = styled('div')`
    display: -webkit-flex;
    gap: 10px;
    opacity: 0;
    div:first-child {
        width: 75%;
    }
    div:last-child {
        display: -webkit-flex;
        gap: 17px;
    }
`;

export const InviteMFRButton = styled(PrimaryButton)`
    font-family: 'DINNextLTPro';
    border: 1px solid ${blueDark02};
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${blueDark02};
    background-color: ${grayLight05};
    &:hover {
        background-color: ${white};
        color: ${blueLink};
        border: 1px solid ${blueLink};
    }
    &:focus {
        background-color: ${grayLight05};
        border: 1px solid ${blueDark02};
        color: ${blueDark02};
    }
`;

export const UploadContentContainer = styled('div')`
    padding: 20px 23px 40px 0;
    width: 100%;
    display: -webkit-flex;
    gap: 36px;
    @media screen and (max-width: 760px) {
        display: block;
    }
`;

export const UploadFileContainer = styled('div')`
    .ant-form-item {
        margin-top: 14px !important;
    }
`;

export const UploadFileSection = styled('div')`
    flex: 1;
    .ant-form-item-explain-error {
        white-space: nowrap;
    }
    .customInputFormItemLabel,
    .customSelectFormItemLabel {
        font-family: 'Proxima N W15 Bold';
        color: ${blueDark01};
        line-height: 16px;
    }
    .ant-form-item {
        margin-top: 20px;
        margin-bottom: 0px;
    }
    .ant-form-item-label > label {
        height: 16px;
        align-items: initial;
    }
    .ant-form-item-label {
        height: 16px;
        margin-bottom: 4px;
    }
    .altTextContainer {
        height: 52px;
    }
    .altTextTipContainer {
        top: -55px;
        left: 155px;
    }
`;

export const UploadInfoSpin = styled(Spin)`
    top: 0 !important;
`;
