import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';

import { authSsoWithPbLoginAction, authSsoNoPbLoginAction, loginAction } from '../../redux/actions/authActions';
import { AuthSsoResponse, LoginActionResponse } from '../../redux/actions/authActions/types';
import { setCookies } from '../../redux/persistedState';

import {
} from './SsoAuth.styles';
import { useAppDispatch } from '../../hooks/redux';
import { useHistory } from 'react-router-dom';
import { PrimaryButton, SpaceCenter, SpaceStart, TypographyText } from '../../styles/common.styles';
import { NavigationKey } from '../../components/appNavigationBar/AppNavigation.types';
import { addPages } from '../../redux/reducers/navigationReducer/NavigationSlice';

const SsoAuth: React.FC = () => {
    const params = new URLSearchParams(location.search);
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        if (codeUrl && codeUrl != '') {
            var ssoToken = ssoAuth(codeUrl, stateUrl);
        }
    }, []);

    let codeUrl = params.get("code"); 
    let stateUrl = params.get("state"); 

    const ssoAuth = async (code: string, state: string ) => {

        const { payload } = (await dispatch(
            authSsoNoPbLoginAction({ code: code, state: state }),
        )) as AuthSsoResponse;

//        const { payload } = (await dispatch(
//            authSsoWithPbLoginAction({ code: code, state: state }),
//        )) as AuthSsoResponse;

        if (!payload || !(payload.user)) {
            document.location.href = "/login";
        }

        var data = { userName: state, ssoToken: payload.user }
        const { payloadLogin } = (await dispatch(
            loginAction({ ...data, userName: data.userName.trim(), ssoToken: payload.user}),
        )) as LoginActionResponse;

        if (payloadLogin?.user) {
            setTimeout(() => {
                setCookies(payload.tokens.accessToken, payload.tokens.refreshToken);
                document.location.href = "/dashboard";
            }, 500);
        } else {
            document.location.href = "/login";
        }
        return payload;
    }

    return (
        <></>
    );
};

export default SsoAuth;
