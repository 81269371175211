import { Button, UploadProps, Image } from 'antd';
import React from 'react';
import { blueDark01, blueLink, grayDark03 } from '../../../styles/colors';
import { Text, BoldText } from '../../../styles/common.styles';
import {
    CreateAccountDragger,
    FileInputContainer,
    FileInputFlexRow,
    FileInputSelected,
    ReDragger,
    CustomButton,
    FileInputAction,
} from './FileInput.styles';
import DeleteModal from '../../common/deleteModal/DeleteModal';
import { useModalState } from '../../../hooks/modalState';

interface Props {
    uploadProps: UploadProps;
    fileName: string;
    parseFile: () => void;
    removeFile: () => void;
}

const FileInput: React.FC<Props> = ({ uploadProps, fileName, parseFile, removeFile }) => {
    const { onOpen, onClose, isOpen } = useModalState();

    const reloadModal = () => {
        removeFile();
        onClose();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Backspace') {
            removeFile();
        }
    };

    return (
        <FileInputContainer>
            <BoldText weight={400} size={13} height={16} color={blueDark01}>
                CSV file
            </BoldText>
            {!fileName ? (
                <CreateAccountDragger
                    multiple={false}
                    showUploadList={false}
                    fileList={undefined}
                    accept=".csv"
                    {...uploadProps}
                >
                    <Image src="src/assets/images/file-lines.svg" preview={false} />
                    <BoldText weight={400} size={14} height={18} color={blueLink}>
                        Choose a file
                    </BoldText>
                    <Text weight={400} size={14} height={18} color={blueDark01}>
                        or drag it here
                    </Text>
                </CreateAccountDragger>
            ) : (
                <div>
                    <FileInputSelected value={fileName} onKeyDown={handleKeyDown} />
                    <ReDragger
                        multiple={false}
                        showUploadList={false}
                        fileList={undefined}
                        accept=".csv"
                        {...uploadProps}
                    >
                        <FileInputFlexRow>
                            <BoldText weight={400} size={14} height={18} color={blueLink}>
                                Choose a file
                            </BoldText>
                            <Text weight={400} size={14} height={18} color={blueDark01}>
                                or drag it here
                            </Text>
                        </FileInputFlexRow>
                    </ReDragger>
                    <DeleteModal
                        isOpen={isOpen}
                        closeModal={onClose}
                        reload={reloadModal}
                        title="file"
                        text={'file'}
                        icon=""
                    />
                </div>
            )}
            <FileInputAction>
                <Text weight={400} size={12} height={14} color={grayDark03}>
                    File types (50Mb maximum):
                </Text>
                {fileName && (
                    <CustomButton onClick={onOpen}>
                        <Image src={`src/assets/images/trash-grey.svg`} preview={false} />
                    </CustomButton>
                )}
            </FileInputAction>
            <Button disabled={!fileName} onClick={parseFile}>
                Save
            </Button>
        </FileInputContainer>
    );
};

export default FileInput;
