import React from 'react';
import { BrandCompany } from '../../../../models/product/types';
import { StoredUser } from '../../../../models/user/user';
import {
    BrandName,
    FreeListingDescription,
    FreeListingContainer,
    FreeListingText,
    FreeListingContact,
} from './FreeListing.styles';

interface Props {
    brandInformation: BrandCompany | undefined;
    user: StoredUser | undefined;
}

const FreeListing: React.FC<Props> = ({ brandInformation, user }) => {

    const getStringInURLEncoding = (str: string): string => {
        return str.replaceAll(' ', '%20').replaceAll('\n', '%0D%0A').replaceAll('&', '%26');
    }

    const getFreeListingEmailToMfrBody = (): string => {
        let body = `Hi [manufacturer name],\n\n`;
        body += `This is [your name, title, contact info].\n\n`;
        body += `I’m sending you this email because I'm researching and/or specifying products in your free listing in the Transparency Catalog. Check it out: www.transparencycatalog.com\n\n`;
        body += `It's the leading source of building & construction products for high-performance, low-carbon buildings, making it super easy for industry professionals to collaboratively select, specify, and procure products, and reward building product manufacturers for making them.\n\n`;
        body += `We think it’s a great tool and are recommending manufacturers contact Sustainable Minds to learn how they can make their products with transparency information findable and the transparency information understandable.\n\n`;
        body += `Thank you.\n\n`;
        body += `Select, specify & procure\n`;
        body += `High-performance building & construction materials that meet carbon impact and material health goals\n`;
        body += `transparencycatalog.com\n`;
        return body;
    }

    const getFreeListingEmailToMfrHref = (): string => {
        const mailTo = `[enter Manufacturer's email address here] `;
        const subject = getStringInURLEncoding(`Do you know about the Transparency Catalog?`);
        const body = getStringInURLEncoding(getFreeListingEmailToMfrBody());
        return `mailto:${mailTo}?cc=support@transparencycatalog.com&subject=${subject}&body=${body}`;
    }

    const getFreeListingEmailToSmBody = (): string => {
        let body = 'Hi Sustainable Minds,\n\n';
        body += `Please contact ${brandInformation.company} on our behalf to request they activate `;
        body += `their listing and add their products. It is OK to use our company name in the request.\n\n`;
        body += 'Thank you,\n\n';
        body += `[Your name, title]\n\n`;
        return body;
    }

    const getFreeListingEmailToSmHref = (): string => {
        const mailTo = 'support@transparencycatalog.com';
        const subject = getStringInURLEncoding(`Contact ${brandInformation.company} to add their products`);
        const body = getStringInURLEncoding(getFreeListingEmailToSmBody());

        return `mailto:${mailTo}?subject=${subject}&body=${body}`;
    }

    return (
        <FreeListingContainer>
            <BrandName>
                <a href={brandInformation?.websiteUrl} target="_blank">
                    {brandInformation?.company}
                </a>
            </BrandName>
            <FreeListingDescription>
                <FreeListingText>
                    <p>
                        <strong>This brand has invested in material ingredient disclosures.</strong>{' '}
                        <a href={brandInformation?.websiteUrl} target="_blank">
                            Visit the website
                        </a>{' '}
                        to find products covered by the material ingredient disclosures below.
                    </p>{' '}
                    <p>
                        Manufacturer, adding products to this listing makes it much easier for AECOs
                        to find, select, specify and procure your products.
                    </p>
                </FreeListingText>
                <FreeListingContact>
                    <p>
                        <strong className="title">Request MFR add their products</strong>
                    </p>{' '}
                    <p>
                        <a href={getFreeListingEmailToMfrHref()}>
                            Contact MFR directly
                        </a>{''}
                        <br />
                        <strong className="text">OR</strong>{' '}
                        <a href={getFreeListingEmailToSmHref()}>
                            ask us to contact them
                        </a>
                    </p>
                </FreeListingContact>
            </FreeListingDescription>
        </FreeListingContainer>
    );
};

export default FreeListing;
