import React from "react";
import { MasterFormatBackground } from "../../models/product/enums";
import { BrandCompany } from "../../models/product/types";
import { CompanyImageContainer, BrandImage } from "./MFRPage.styles";
import CompanyInfo from "./mfrSection/companyInfo/CompanyInfo";
import FreeListing from "./mfrSection/freeListing/FreeListing";
import { CompanyNameContainer, ContactInfoContainer, ContactUsContainer, EmailLink, NotInCatalogMessageContainer } from "./MFRPageCompanyHeader.styles";

interface Props {
    company?: BrandCompany;
    onToggle: () => void;
    user: StoredUser | undefined;
};

export const MFRPageCompanyHeader: React.FC<Props> = ({ company, onToggle, user }) => {
    if (!company) {
        return <></>;
    }

    const getStringInURLEncoding = (str: string): string => {
        return str.replaceAll(' ', '%20').replaceAll('\n', '%0D%0A');
    }

    const getOutsideTCEmailBody = (): string => {
        let body = 'Hi Sustainable Minds,\n\n';
        body += `Please contact ${company.company} on our behalf to request they create `;
        body += `transparency documentation so they can be added to the Transparency Catalog. `;
        body += `We have manually added them to our Project and/or Library for now.\n\n`;
        body += `Thank you,\n\n`;
        body += `[Your name, title]\n\n`;
        return body;
    }

    const getOutsideTCEmailHref = (): string => {
        const mailTo = 'support@transparencycatalog.com';
        const subject = getStringInURLEncoding(`Contact ${company.company} to create EPDs / material disclosures`);
        const body = getStringInURLEncoding(getOutsideTCEmailBody());
        return `mailto:${mailTo}?subject=${subject}&body=${body}`;
    }

    if (company.type !== 'userCompanies') {
        return (
            <>
                <CompanyImageContainer>
                    {company?.logo?.url && company?.logo?.url !== null && company?.featuredProductImage && (
                        <BrandImage src={company?.logo.url} height={company?.logo.height} width={company?.logo.width} preview={false} />
                    )}
                </CompanyImageContainer>
                {company?.featuredProductImage === null && company?.level !== MasterFormatBackground.FREE_LISTING && (
                    <div style={{height: '30px'}}>&nbsp;</div>
                )}
                {company?.level === MasterFormatBackground.FREE_LISTING ? (
                    <FreeListing brandInformation={company} user={user} />
                ) : (
                    <CompanyInfo brandInformation={company} onToggle={onToggle} user={user} />
                )}
            </>
        );
    }

    return (
        <>
            <CompanyNameContainer>
                {company.company}
            </CompanyNameContainer>
            <ContactInfoContainer>
                <NotInCatalogMessageContainer>
                    <span>
                        <span className='bold'>
                            {company.company} is not in the Transparency Catalog
                        </span> because they haven’t yet invested in EPDs or material disclosures for their products.
                    </span>
                </NotInCatalogMessageContainer>
                <ContactUsContainer>
                    <EmailLink href={getOutsideTCEmailHref()} style={{color:'#134FBD'}}>
                        Contact us
                    </EmailLink>
                    <span> so we can invite them to get started.</span>
                </ContactUsContainer>
            </ContactInfoContainer>
        </>
    );
};

export default MFRPageCompanyHeader;